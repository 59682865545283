import React from 'react';
import './experience.css';
import { BsPatchCheckFill } from 'react-icons/bs';

const Experience = () => {
  return (
    <section id="experience">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        {/* Frontend Skills */}
        <div className="experience_frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            {[ 
              { name: "HTML", level: "Experienced" },
              { name: "CSS", level: "Intermediate" },
              { name: "JavaScript", level: "Experienced" },
              { name: "Bootstrap", level: "Experienced" },
              { name: "React", level: "Experienced" },
              { name: "Angular", level: "Experienced" },
              { name: "Java FX", level: "Experienced" }
            ].map((skill, index) => (
              <article className="experience__details" key={index}>
                <BsPatchCheckFill className="experience__details-icon" />
                <div>
                  <h4>{skill.name}</h4>
                  <small className="text-light">{skill.level}</small>
                </div>
              </article>
            ))}
          </div>
        </div>

        {/* Backend Skills */}
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            {[ 
              { name: "Node JS", level: "Experienced" },
              { name: "MongoDB", level: "Intermediate" },
              { name: "PHP", level: "Experienced" },
              { name: "MySQL", level: "Experienced" },
              { name: "Firebase", level: "Experienced" },
              { name: "AWS", level: "Experienced" }
            ].map((skill, index) => (
              <article className="experience__details" key={index}>
                <BsPatchCheckFill className="experience__details-icon" />
                <div>
                  <h4>{skill.name}</h4>
                  <small className="text-light">{skill.level}</small>
                </div>
              </article>
            ))}
          </div>
        </div>

        {/* Languages Skills */}
        <div className="experience_frontend">
          <h3>Languages</h3>
          <div className="experience__content">
            {[ 
              { name: "Java", level: "Experienced" },
              { name: "JavaScript", level: "Intermediate" },
              { name: "Python", level: "Experienced" },
              { name: "C++", level: "Experienced" },
              { name: "PHP", level: "Experienced" },
              { name: "React", level: "Experienced" }
            ].map((skill, index) => (
              <article className="experience__details" key={index}>
                <BsPatchCheckFill className="experience__details-icon" />
                <div>
                  <h4>{skill.name}</h4>
                  <small className="text-light">{skill.level}</small>
                </div>
              </article>
            ))}
          </div>
        </div>

        {/* Project Management & UAT Skills */}
        <div className="experience__project_management">
          <h3>Project Management & UAT</h3>
          <div className="experience__content">
            {[ 
              { name: "Project Planning", level: "Expert" },
              { name: "Cross-Functional Collaboration", level: "Expert" },
              { name: "User Acceptance Testing (UAT)", level: "Expert" },
              { name: "Risk Management", level: "Advanced" },
              { name: "Agile & Scrum Methodologies", level: "Advanced" },
              { name: "Stakeholder Management", level: "Expert" }
            ].map((skill, index) => (
              <article className="experience__details" key={index}>
                <BsPatchCheckFill className="experience__details-icon" />
                <div>
                  <h4>{skill.name}</h4>
                  <small className="text-light">{skill.level}</small>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
