import React from 'react';
import './services.css';
import { BiCheck } from 'react-icons/bi';

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        {/* Front End Development */}
        <article className="service">
          <div className='service__head'>
            <h3>Front End Development</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon' /><p>Creating responsive and visually appealing user interfaces.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Using HTML, CSS, and JavaScript to build interactive web applications.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Proficient in creating and styling the structure of web pages using HTML.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Strong understanding of CSS for styling and layout.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Knowledge of JavaScript, including working with variables, data types, and basic functions.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Understanding of DOM manipulation and event handling.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Familiarity with popular CSS frameworks such as Bootstrap or Tailwind CSS</p></li>
          </ul>
        </article>

        {/* Backend Development */}
        <article className="service">
          <div className='service__head'>
            <h3>Backend Development</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon' /><p>Building server-side logic and databases for applications.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Working with server-side frameworks and technologies like Node.js</p></li>
            <li><BiCheck className='service__list-icon' /><p>Ability to leverage languages in Java, JavaScript, C++, React, and Python.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Understanding and working with databases (SQL and/or NoSQL/Firebase/-Intermediate AWS experience)</p></li>
            <li><BiCheck className='service__list-icon' /><p>Implementing user authentication and authorization systems.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Proficient use of version control systems, especially Git.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Understanding deployment processes.</p></li>
          </ul>
        </article>

        {/* Database Management */}
        <article className="service">
          <div className='service__head'>
            <h3>Database Management</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon' /><p>Familiarity with both relational databases (e.g., MySQL, PostgreSQL) and NoSQL databases.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Proficient knowledge of SQL.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Understanding the basics of designing a database schema.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Creating tables, defining relationships, and establishing primary and foreign keys.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Creating entity-relationship diagrams (ERD) to represent the structure of a database.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Proficiency in CRUD operations (Create, Read, Update, Delete) for managing data.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Basic understanding of connecting applications to databases using programming languages.</p></li>
          </ul>
        </article>

        {/* Project Management */}
        <article className="service">
          <div className='service__head'>
            <h3>Project Management</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon' /><p>Leading and managing project timelines, ensuring delivery on time.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Coordinating with cross-functional teams and stakeholders.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Creating detailed project plans and defining clear milestones.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Managing resources effectively to ensure projects run smoothly.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Risk management and resolution of project bottlenecks.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Providing regular status updates to clients and internal teams.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Using project management tools like Jira, Asana, or Trello for task tracking and communication.</p></li>
          </ul>
        </article>

        {/* User Acceptance Testing (UAT) */}
        <article className="service">
          <div className='service__head'>
            <h3>User Acceptance Testing (UAT)</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon' /><p>Creating UAT test cases based on project requirements and specifications.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Collaborating with business stakeholders to validate the application against user needs.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Performing manual testing and documenting results for bug tracking and reporting.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Identifying, reporting, and helping resolve user-facing issues or bugs.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Ensuring that the final product meets the quality standards set by the stakeholders.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Coordinating with the QA team to ensure effective issue resolution.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Providing feedback for improvements and optimizations based on UAT findings.</p></li>
          </ul>
        </article>

        {/* Skills */}
        <article className="service">
          <div className='service__head'>
            <h3>Skills</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon' /><p>Strong communication skills and the ability to work collaboratively within a team.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Problem-solving and critical-thinking skills.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Providing clear and comprehensive documentation for your code and APIs.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Proficient in debugging techniques to identify and resolve issues in your code.</p></li>
            <li><BiCheck className='service__list-icon' /><p>The ability to approach problems analytically and logically.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Willingness to tackle challenges independently and seek solutions.</p></li>
            <li><BiCheck className='service__list-icon' /><p>Attention to detail in writing and reviewing code.</p></li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
