import React from 'react';

const CTA = () => {
  return (
    <div className="cta">
      {/* Link to the resume inside the public folder */}
      <a href="/Cyril K Thomas Resume.pdf" download className="btn">
        Download CV
      </a>
      <a href="#contact" className="btn btn-primary">Let's Talk</a>
    </div>
  );
}

export default CTA;
