import React from 'react'
import './about.css'
import ME from '../../assets/sort2.JPG'
import SIDE from '../../assets/sort1.JPG'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

    <div className="container about__container">
      <div className="about__me">
        <div className="about__me-image">
          <img src ={ME} alt ="About Image"/>
        </div>
      </div>
      <div className="about__content">
        <div className="about__cards">
          <article className='about__card'>
            <FaAward className='about__icon'/>
            <h5>Experience</h5>
            <small>0-4 Years Working Experience</small>
          </article>

          
          <article className='about__card'>
            <VscFolderLibrary className='about__icon'/>
            <h5>Projects</h5>
            <small>20+ Completed</small>
          </article>
        </div>
<div>
        <p>



        Greetings!
Hi, I'm Cyril—a tech-savvy professional with a strong background in project management and a passion for crafting seamless digital experiences. My journey began with mastering HTML, CSS, and JavaScript to build intuitive, user-friendly interfaces. Over time, I’ve expanded my expertise, delving into frameworks like React for dynamic front-end solutions and Express.js for efficient server-side operations.

💡 My experience also spans database management, where I enjoy structuring and maintaining data with both SQL and MongoDB. I’m equally passionate about deploying robust applications on platforms like Heroku and AWS, ensuring scalability and performance.

🚀 As a Project Manager, I’ve honed my ability to lead cross-functional teams and oversee the successful execution of projects, including conducting thorough UAT to ensure solutions meet end-user needs. I thrive in collaborative environments and bring a results-driven approach to every challenge.

🌐 I stay connected to industry trends through meetups and continuous learning, eager to apply my skills to innovative projects. Let’s connect to explore opportunities where technology and strategy align to create impactful solutions!
        </p>
</div>
        <a href="#contact" className='btn btn-primary'> Let's Talk </a>

      </div>
    </div>
    </section>
  )
}

export default About